.background {
  width: 100%; 
  height: 100%; 
  background: url("../img/bg_taxirio_sistema_opacity.jpg"); 
  background-repeat: no-repeat;
  background-size: cover ;
  bottom:0px;
  position: absolute
  ;
  
}

.logo-img {
  width:35%;
}

#btn-login {
  font-weight: bolder;
  background-color: #ffcd5e;
}