.title {
  
  background-color: #c4c4c4 -moz-linear-gradient(top,rgba(255,255,255,0.8),rgba(255,255,255,0));
  background-color: rgb(196, 196, 196);
  border: 1px solid #a8a8a8;
}

.header {
  color: #4f4f4f;
  vertical-align: middle;
  text-align: center;
  font-weight: bold;
}
.resultado {
  margin: 15px;
}

.linha {
}

.celula {
  border: 1px solid #ccc;
  padding-top:3px;
  padding-bottom:3px;
  font-size: 0.9em;
  vertical-align: middle;
  display: flex;
  align-items: center;
}

.importar-action {
  text-align: center;
}

.importar-action div{
  cursor: pointer;
}

.botao-desabilitado {
  cursor: not-allowed;
}

.foto-motorista {
  height: 32px;
  width: 32px;
  margin-right: 8px;
  border-radius: 16px;
  border: solid 1px #dedede;
  background-color: #dedede;
  transition: all .3s;
  color:#999;
}

.foto-motorista:hover {
  height: 56px;
  width: 56px;
  border-radius: 3px;
}